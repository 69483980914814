import { AlertStatus } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FC, useEffect, useRef } from 'react';

import useStores from '../../../../../hook/useStores';
import RootStore from '../../../../../stores/RootStore';

interface IProps {}

const TimeGrid: FC<IProps> = () => {
    const gridRef = useRef<HTMLCanvasElement>(null);

    const {
        RootStore: {
            ucrStore: { cellWidth, hourStart, hourEnd, hcpsPos, staffAlerts, selectedDate },
            schedulesStore: { allSchedules },
        },
    } = useStores<{ RootStore: RootStore }>();

    /**
     * This is the logic for drawing the swimline grid.
     * The canvas by default is transparent,
     * then it draws the grid in 3 major parts:
     *     1. Draws the available times in white rectangles
     *        and urgent warning red for an entire swimlane
     *     2. Draws the hourly vertical lines
     *     3. Draws the horizontal lines
     */
    useEffect(() => {
        if (!gridRef.current) return;

        const DPI = window?.devicePixelRatio || 1;

        // Scale the drawing area by pixel density (to prevent blurring)
        gridRef.current.width = (gridRef.current.offsetWidth || 0) * DPI;
        gridRef.current.height = (gridRef.current.offsetHeight || 0) * DPI;

        const ctx = gridRef.current.getContext('2d') as CanvasRenderingContext2D;

        ctx.scale(DPI, DPI);

        // An array with the numbers of the displayed hours. From hourStart to hourEnd.
        const hoursArr = Array.from({ length: hourEnd - hourStart + 1 }, (_, i) => i + hourStart);
        const hcpsKeys = Object.keys(hcpsPos);

        //
        // Draw available time in lighter colour and urgent warning swimlanes
        //
        hcpsKeys.forEach((key) => {
            const schedules = allSchedules.filter((schedule: any) => schedule.userId === key);
            const { top, height } = hcpsPos[key];

            schedules.forEach((schedule: any) => {
                const startTime = moment(schedule.startDateTime);
                const endTime = moment(schedule.endDateTime);
                const endsNextDay = endTime.isAfter(moment(selectedDate).endOf('day'));

                const startHour = startTime.hour() + startTime.minute() / 60;
                const endHour = endsNextDay ? 24 : endTime.hour() + endTime.minute() / 60;

                const left = cellWidth * (startHour - hourStart);
                const width = cellWidth * (endHour - startHour);

                ctx.fillStyle = 'rgb(255, 255, 255)';
                ctx.fillRect(left, top, width, height);
            });

            if (
                staffAlerts.some(
                    (alert: any) => alert.status === AlertStatus.OPEN && alert.userId === key,
                )
            ) {
                ctx.fillStyle = 'rgba(255, 0, 0, .1)';
                ctx.fillRect(0, top, (gridRef.current?.width || 0) + 1, height);
            }
        });

        // Transparency depends on the pixel density. Otherwise it will be too saturated.
        ctx.strokeStyle = `#ccc`;

        ctx.beginPath();

        //
        // Draw the vertical lines down the screen - Aligend with hours
        //
        hoursArr.forEach((hour, index) => {
            if (index === 24) return;

            // Position of the vertical lines for each hour.
            // For an odd width, it should start in the middle of the pixel.
            const posX = cellWidth * (hour - hourStart) - 0.5;

            ctx.moveTo(posX, 0);
            ctx.lineTo(posX, (gridRef.current?.height || 0) + 1);
        });

        //
        // Draw the horizontal lines accross the grid - Aligned with the HCP info boundries
        //
        hcpsKeys.forEach((key, index: number) => {
            if (index === hcpsKeys.length - 1) return;

            // Similar to hour lines, positions of the horizontal lines for each HCP.
            const posY = hcpsPos[key].top + hcpsPos[key].height - 0.5;

            ctx.moveTo(-0.5, posY);
            ctx.lineTo((gridRef.current?.width || 0) + 1, posY);
        });

        ctx.stroke();
    }, [gridRef, cellWidth, hourStart, hourEnd, hcpsPos, allSchedules, staffAlerts, selectedDate]);

    return <canvas ref={gridRef} className="ucr__calendar-time-grid" />;
};

export default observer(TimeGrid);

import { useLazyQuery } from '@apollo/client';
import { JobStatus } from '@doc-abode/data-models';
import moment from 'moment';
import { useEffect } from 'react';

import { VisitValuesType } from '../components/pages/ucr/blocks/panels/VisitDetailsTypes';
import { GET_WARNINGS } from '../graphql/queries/jobs';

const useVisitWarnings = (values: VisitValuesType) => {
    const [getWarnings, { data }] = useLazyQuery(GET_WARNINGS);
    const isCompleted = values.jobStatus === JobStatus.COMPLETED;

    const startTime = isCompleted ? values.arrivedDateTime : values.startTime;
    const duration = isCompleted
        ? moment(values.finishedDateTime)
              .subtract(moment(startTime).hours(), 'hours')
              .subtract(moment(startTime).minutes(), 'minutes')
              .format('HH:mm')
        : values.duration;

    useEffect(() => {
        if (startTime && (!values.nhsNumber || /^\d{10}$/.test(values.nhsNumber))) {
            const startDateTime = moment(values.visitDate)
                .startOf('day')
                .add(moment(startTime).hours(), 'hours')
                .add(moment(startTime).minutes(), 'minutes')
                .milliseconds(0)
                .utc()
                .format();

            getWarnings({
                variables: {
                    warning: {
                        startDateTime,
                        duration,
                        nhsNumber: values.nhsNumber,
                        hcpIds: [values.hcpId, values.buddyId].filter((_) => _),
                        id: values.id,
                    },
                },
            });
        }
    }, [
        startTime,
        duration,
        values.nhsNumber,
        values.hcpId,
        values.buddyId,
        values.id,
        getWarnings,
        values.visitDate,
    ]);

    const { warnings } = data?.getWarnings || {};

    return warnings;
};

export const useScheduleWarnings = (values: VisitValuesType, hcpIds: string[]) => {
    const [getWarnings, { data }] = useLazyQuery(GET_WARNINGS);

    const startTime = values.startTime;
    const duration = values.duration;

    useEffect(() => {
        const startDateTime = moment(values.visitDate)
            .startOf('day')
            .add(moment(startTime).hours(), 'hours')
            .add(moment(startTime).minutes(), 'minutes')
            .utc()
            .format();

        if (!values.nhsNumber || /^\d{10}$/.test(values.nhsNumber)) {
            getWarnings({
                variables: {
                    warning: {
                        startDateTime,
                        duration: duration,
                        nhsNumber: values.nhsNumber,
                        hcpIds: [...hcpIds],
                        id: values.id,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime, duration, values.nhsNumber, values.id, getWarnings, values.visitDate]);

    const { warnings } = data?.getWarnings || {};

    return warnings;
};

export default useVisitWarnings;

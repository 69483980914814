import { RoutePatientInput, Vaccination, VaccinationCategory } from '@doc-abode/data-models';

import { formatTimeWithDate } from '../../../modules/helpers/formatData';
import { routeTypes } from './constants';

interface IFormatPatientForRoute {
    patient: Vaccination;
    selectedDate?: string;
    vaccinationDuration: Record<string, string>;
    vaccinationDetails: Record<string, { requiresPickup: boolean }>;
    routeType: string;
    jobs: Vaccination[];
    skipPickup?: boolean;
}
export const formatPatientForRoute = ({
    patient,
    selectedDate,
    vaccinationDuration,
    vaccinationDetails,
    routeType,
    jobs,
    skipPickup,
}: IFormatPatientForRoute): RoutePatientInput | null => {
    const {
        id,
        addressLine1,
        addressLine2,
        town,
        postCode,
        availableFrom,
        availableTo,
        vaccinationCategory,
        latitude,
        longitude,
        nhsNumber,
        odsCode,
    } = patient;

    const requiresPickup = Boolean(vaccinationDetails[vaccinationCategory]?.requiresPickup);
    const patientData = {
        jobId: id,
        duration: vaccinationDuration[vaccinationCategory] || '00:10:00',
        quantity: [vaccinationCategory === VaccinationCategory.COVID_19 ? 1 : 0],
        openingTime: availableFrom ? formatTimeWithDate(availableFrom, selectedDate) : undefined,
        closingTime: availableTo ? formatTimeWithDate(availableTo, selectedDate) : undefined,
        skipPickup,
        ...(requiresPickup && {
            requiresPickup,
            pickupFrom: odsCode,
            pickupDuration: vaccinationDuration['GP_PICKUP'] || '00:20:00',
        }),
    };

    if (
        vaccinationCategory !== VaccinationCategory.COVID_19 &&
        routeType === routeTypes.COVID &&
        jobs.length
    ) {
        const relatedJob = jobs.find(
            (job) =>
                job.vaccinationCategory === VaccinationCategory.COVID_19 &&
                job.nhsNumber === nhsNumber,
        );

        if (!relatedJob) {
            return null;
        }

        Object.assign(patientData, { dropOffFrom: [relatedJob.id] });
    }

    if (latitude && longitude) {
        Object.assign(patientData, { location: { latitude, longitude } });
    } else {
        Object.assign(patientData, {
            address: [addressLine1, addressLine2, town, postCode].filter((_) => _).join(', '),
        });
    }

    return patientData;
};

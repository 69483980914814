import { JobStatus, JobType } from '@doc-abode/data-models';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { isJobExpired, timeDifferenceInWeeks } from '../components/modules/helpers/formatData';
import AppToaster from '../components/modules/helpers/Toaster';
import { sortDateDescending } from '../helpers/sorting';
import RootStore from './RootStore';

class JobsStore {
    rootStore: RootStore;
    jobType: string;
    refreshTimeout: number;

    constructor(rootStore: RootStore, jobType?: string, refreshTimeout?: number) {
        makeObservable(this, {
            isLoadingJobs: observable,
            isUpdatingJobs: observable,
            filters: observable,
            page: observable,
            selected: observable,
            sortFn: observable,
            selectableCriteria: observable,
            jobStatusToEmphasise: observable,
            showAbortedByController: observable,
            filterAdapters: observable,
            selectedOnly: observable,
            jobsByStatus: observable,
            pageCount: computed,
            allJobs: computed,
            pagedJobs: computed,
            pageStart: computed,
            pageEnd: computed,
            totalJobsNumber: computed,
            selectedJobs: computed,
            allSelected: computed,
            selectable: computed,
            loading: computed,
            setJobs: action,
            setLoadingJobs: action,
            clearToasts: action,
            getJob: action,
            doesJobRequireAttention: action,
            setFilters: action,
            setFilterAdapter: action,
            setPage: action,
            select: action,
            deselect: action,
            selectAll: action,
            setSortFn: action,
            cleanUpSelected: action,
            setSelectedOnly: action,
            toggleShowAbortedByController: action,
        });

        this.rootStore = rootStore;
        this.jobType = jobType ?? '';
        this.refreshTimeout = refreshTimeout ?? 60000;
    }

    isLoadingJobs = true;
    isUpdatingJobs = false;

    uiJobStatuses = [
        'pending',
        'available',
        'accepted',
        'current',
        'arrived',
        'requireAttention',
        'historic',
        'controllerAborted',
    ];

    // Below type of any is because it should be Vaccination | Route, typscript was complaining when I set it to this about missing types.
    jobsByStatus: { [key: string]: any[] } = Object.fromEntries(
        this.uiJobStatuses.map((key) => [key, []]),
    );

    filters = {};
    filterAdapters: any[] = [];
    page = 0;
    pageSize = 20;
    selected: string[] = [];
    selectableCriteria: any[] = [];
    selectedOnly: boolean = false;
    sortFn: any = null;
    jobStatusToEmphasise: string | null = null;
    showAbortedByController = false;

    get loading() {
        return (this.isLoadingJobs && this.totalJobsNumber === 0) || this.isUpdatingJobs;
    }

    select = (id: string) => {
        if (!this.selected.includes(id)) {
            this.selected.push(id);
        }
    };

    deselect = (id: string) => {
        const index = this.selected.indexOf(id);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    get selectable() {
        let selected = this.allJobs;
        this.selectableCriteria.forEach((filterFn) => {
            selected = selected.filter(filterFn);
        });
        return selected;
    }

    selectAll = () => {
        this.selectable.forEach((job) => this.select(job.id));
    };

    deselectAll = () => {
        this.selected = [];
    };

    get allSelected() {
        return this.selectable.every((job) => this.selected.includes(job.id));
    }

    cleanUpSelected = () => {
        this.selected = this.selected.filter((id) => this.allJobs.some((job) => job.id === id));
    };

    setSortFn = (sortFn: any) => {
        this.sortFn = sortFn;
    };

    get selectedJobs() {
        return this.allJobs.filter((job) => this.selected.includes(job.id));
    }

    setSelectedOnly = (value: boolean) => {
        this.selectedOnly = value;
    };

    fetchInterval: ReturnType<typeof setInterval> | null = null;

    get allJobs() {
        let allJobs = this.showAbortedByController
            ? this.jobsByStatus['controllerAborted']
            : this.uiJobStatuses.flatMap((jobStatus) =>
                  jobStatus !== 'controllerAborted' && this.jobsByStatus[`${jobStatus}`]
                      ? this.jobsByStatus[`${jobStatus}`]
                      : [],
              );

        if (allJobs.length === 0) {
            return [];
        }

        allJobs = allJobs.map((job) => ({
            ...job,
            selected: this.selected.includes(job.id),
            requiresAttention: this.doesJobRequireAttention(job),
        }));

        if (this.selectedOnly) {
            allJobs = allJobs.filter((job) => job.selected);
        }

        if (this.sortFn) {
            allJobs.sort(this.sortFn);
        }

        return allJobs;
    }

    get totalJobsNumber() {
        return this.allJobs.length;
    }

    get pageStart() {
        return this.page * this.pageSize;
    }

    get pageEnd() {
        return this.pageStart + this.pageSize;
    }

    get pagedJobs() {
        return this.allJobs.slice(this.pageStart, this.pageEnd);
    }

    get pageCount() {
        return this.totalJobsNumber > 0 ? Math.ceil(this.totalJobsNumber / this.pageSize) : 0;
    }

    setPage = (page: number) => (this.page = page);

    setJobs = async (jobs: any[]) => {
        // sort taken from getJobs lambda, jobsBody used as var name as I dont want to change the runInAction logic just yet.
        const jobStatusesToSort = [
            JobStatus.AVAILABLE,
            JobStatus.ACCEPTED,
            JobStatus.CURRENT,
            JobStatus.ARRIVED,
            JobStatus.WITHDRAWN,
            JobStatus.COMPLETED,
            JobStatus.CONTROLLER_ABORTED,
            JobStatus.HCP_ABORTED,
        ];

        const jobsBody: { [key: string]: { Items: any[] } } = {};

        for (const status of jobStatusesToSort) {
            let tmpJobs = jobs.filter((job) => job.jobStatus === status);

            jobsBody[`${status}`.toLowerCase()] = {
                Items: tmpJobs,
            };
        }

        // Below logic not changed, looks like old code and could be tidied up.
        runInAction(() => {
            this.uiJobStatuses.forEach((jobStatus) => {
                let sorted: Array<any> = [];

                if (
                    jobStatus === 'pending' ||
                    jobStatus === 'available' ||
                    jobStatus === 'accepted' ||
                    jobStatus === 'current'
                ) {
                    sorted = jobsBody[jobStatus]?.Items || [];
                    // Remove expired jobs
                    // Remove jobs that have no HCPs Notified
                    sorted = sorted.filter((sortedJob) => !this.doesJobRequireAttention(sortedJob));
                }

                // Merge the WITHDRAWN, HCP_ABORTED, expired: AVIALABLE, ACCEPTED, CURRENT
                else if (jobStatus === 'requireAttention') {
                    sorted = _.flatten(
                        [
                            'pending',
                            'available',
                            'accepted',
                            'current',
                            'arrived',
                            'withdrawn',
                            'hcp_aborted',
                        ].map((status) => {
                            sorted = jobsBody[status]?.Items || [];
                            return sorted.filter(this.doesJobRequireAttention);
                        }),
                    );
                }

                // Completed Jobs
                else if (jobStatus === 'historic') {
                    sorted = jobsBody.completed?.Items || [];
                }

                // Controller Aborted Jobs
                else if (jobStatus === 'controllerAborted') {
                    sorted = jobsBody.controller_aborted?.Items || [];
                }

                // Any other job type (e.g. ARRIVED)
                else {
                    sorted = jobsBody[jobStatus]?.Items || [];
                    sorted = sorted.filter((sortedJob) => !this.doesJobRequireAttention(sortedJob));
                }

                // Sort the respective list of jobs
                if (sorted.length > 1) {
                    sorted = sorted.sort((a, b) =>
                        sortDateDescending(a.createDateTime, b.createDateTime),
                    );
                }

                // Set the jobs in the store
                // only if the observable for that job type exists,
                // so that we don't have unwanted observables polluting the store
                this.jobsByStatus[`${jobStatus}`] = sorted;
            });
        });
    };

    setLoadingJobs = (status: boolean) => {
        this.isLoadingJobs = status;
    };

    clearToasts = () => AppToaster.clear();

    // Finds a job in any list by ID and returns it
    getJob = (jobId: string) => {
        return _.find(this.allJobs, { id: jobId });
    };

    // Finds a job in any list by ID and determine if it requires attention or not
    doesJobRequireAttention = (job: any) => {
        const jobStatus = job.jobStatus.toLowerCase();
        const isExpired = isJobExpired(job.expiryDateTime);
        const isVaccination = job.jobType === JobType.VACCINATION;
        const isRoute = job.jobType === JobType.ROUTE;

        if (
            jobStatus === 'withdrawn' ||
            jobStatus === 'hcp_aborted' ||
            (isExpired && isRoute && jobStatus === 'available') ||
            (isVaccination &&
                job.dateOfDose1 &&
                timeDifferenceInWeeks(job.dateOfDose1) > 9 &&
                jobStatus !== 'completed' &&
                jobStatus !== 'controller_aborted')
        ) {
            return true;
        } else {
            return false;
        }
    };

    runFilterAdapters = () => {
        let filters: { [key: string]: string[] } = { ...this.filters };
        this.filterAdapters.forEach((adapter) => {
            filters = adapter(filters);
        });
        return filters;
    };

    setFilterAdapter = (adapter: any) => {
        this.filterAdapters.push(adapter);
    };

    setFilters = async (filters: { [key: string]: any }) => {
        if (filters) {
            this.filters = filters;
            this.setPage(0);
        }
        this.cleanUpSelected();
    };

    toggleShowAbortedByController = () => {
        this.showAbortedByController = !this.showAbortedByController;
        this.page = 0;
    };
}

export default JobsStore;
